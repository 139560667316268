import React, { useEffect, useMemo, useRef, useState } from 'react';

import './portfolio.css';

import MyProfile from '../profile/myprofile.tsx';
import Knowledge from '../knowledge/knowlegde.tsx';
import CustomParticles from '../particles/particles.tsx';
import Projects from '../projects/projects.tsx';
import History from '../history/history.tsx';
import Footer from '../footer/footer.tsx';
import Theme from '../theme/theme.tsx';

import { initTheme, theme } from '../../services/utils.service.tsx';
import EventEmitter, { Events } from '../../services/event.tsx';
import { SendStatistic } from '../../services/plausible.service.tsx';

export default function Portfolio() {
    const [selectedTheme, setSelectedTheme] = useState(theme);
    
    document.title = 'Portfolio × Clément LEMAY ';
    
    initTheme();
    EventEmitter.subscribe(Events.theme, (lee) => {
        setSelectedTheme(lee);
    });
    useEffect(() => {
        SendStatistic();
    }, []);

    return (
        <div className="global-container flex flex-wrap justify-center min-h-full">
            <CustomParticles />
            <MyProfile />
            <div className='content-container flex flex-col items-center min-h-screen sm:w-5/6 md:w-2/3' style={{ gap: '30vh' }}>
                <Knowledge />
                <Projects />
                <History />
            </div>
            <Footer />
            <Theme />
        </div>
    )
}

