export enum Events {
    onClickScene = 'onClickScene',
    onToggleFocus = 'onToggleFocus',
    theme = 'theme'
}

export const EventEmitter = {
    _events: {},
    dispatch(event: Events, data: any) {
        if (!this._events[event]) return;
        this._events[event].forEach(callback => callback(data))
    },
    subscribe(event: Events, callback: (data: any) => any) {
        if (!this._events[event]) this._events[event] = [];
        this._events[event].push(callback);
    }
}

export default EventEmitter;
