import React, { useRef } from 'react';

import './theme.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToggleTheme, theme } from '../../services/utils.service.tsx';


export default function Theme() {
    const [icon, setIcon] = React.useState(theme === 'dark' ? faSun : faMoon);

    library.add(faMoon);
    const handleClick = () => {
        const activeTheme = theme;
        if (activeTheme === 'dark') {
            setIcon(faMoon);
        } else {
            setIcon(faSun);
        }
        ToggleTheme();
    }

    return (
        <div onClick={() => handleClick()}  className='theme-container flex items-center justify-center absolute rounded-full bg-white h-16 w-16 md:bottom-10 md:right-10 sm:bottom-2 sm:right-2 z-20'>
            <button >
                <FontAwesomeIcon icon={icon} />
            </button>
        </div>
    )
}
