import React, { useRef } from 'react';

import IsOnScreen from '../../services/utils.service.tsx';
import './card.css';
import { Badge } from '../history/card.tsx';

export function Card(props: ICard) {

    const refCard = useRef<HTMLDivElement>(null);
    const cardIsVisible = IsOnScreen(refCard);

    const badgeList: any[] = [];

    props.badges?.forEach(x => {
        badgeList.push(<Badge text={x}></Badge>)
    })

    return (
        <article ref={refCard} data-visible={cardIsVisible} className="projet-card overflow-hidden rounded-lg shadow-sm">

            <div className="p-4 sm:p-6">
                <span className='text-6xl'>{props.img}</span>
                <h3 className="text-3xl font-medium pt-5">
                    {props.title}
                </h3>

                <p className="mt-2 line-clamp-3 text-xl">
                    {props.text}
                </p>
            </div>
            <div className='absolute bottom-2 flex flex-wrap px-1 gap-1'>
                {badgeList}
            </div>
        </article>
    )
}

export interface ICard {
    title: string;
    text: string;
    badges: string[];
    img: any;
} 

