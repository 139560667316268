import React, { useRef } from 'react';

import './history.css';
import Card, { HistoryCard } from './card.tsx';
import IsOnScreen from '../../services/utils.service.tsx';
import { title } from 'process';

export default function History() {

    const CardListData: HistoryCard[] = [
        {
            title: 'ENI - Concepteur développeur d’applications',
            badges: ['Alternance', 'Conception'],
            datesRange: '2019 - 2021',
            description: 'Prendre en charge les études fonctionnelles et techniques. Spécifier et concevoir techniquement une application informatique de type client/serveur. n-tiers ou mobile. Programmer une application informatique. Participer à la gestion de projet informatique.'
        },
        {
            title: 'ENI - Manager de Solutions Digitales et Data',
            badges: ['Alternance', 'Pilotage'],
            datesRange: '2021 - 2022',
            description: 'Organiser un système d’information. Acquérir les notions fondamentales technologiques et méthodologiques permettant la gestion d’un système d’information. Gérer une équipe (management, conduite de réunion, recrutement). Gérer un projet informatique.'
        },
        {
            title: 'Capgemini - Concepteur développeur d’applications',
            badges: ['Developpeur', 'DevOps', 'Design'],
            datesRange: '2022 - Maintenant',
            description: 'Concevoir et développer des fonctionnaltés sur des projets innovants. Réaliser l\'accompagement de nouveaux arrivants. Réaliser la maintenance et l\'optimisation des environnements.'
        },
    ]

    const refTitle = useRef<HTMLDivElement>(null);
    const titleIsVisible = IsOnScreen(refTitle);

    const cardContainer = [];

    CardListData.forEach(x => {
        cardContainer.push(<Card data={x} />);
    });

    return (
        <div className='flex flex-col relative'>
            <h4 className='md:text-4xl sm:text-2xl font-meduim tracking-widest text-center' ref={refTitle} data-visible={titleIsVisible}>EXPERIENCES</h4>
            <div className='history-card-container flex md:flex-row sm:flex-col-reverse gap-10 z-10'>
                {cardContainer}
            </div>
            {/* <div className='bar absolute w-full h-16 top-1/2 z-5'></div> */}
        </div>
    );
}
