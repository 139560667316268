import React, { useRef } from 'react';
import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';
import { faGitlab, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

import profilePicture from '../../assets/img/profile.jpg';

import './myprofile.css';
import IsOnScreen from '../../services/utils.service.tsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function MyProfile() {
    library.add(faGitlab, faLinkedinIn);
    const ref = useRef<HTMLDivElement>(null);
    const isVisible = IsOnScreen(ref);

    const options: ILink[] = [
        {
            label: "GitLab",
            link: "https://gitlab.com/clementlemay",
            icon: faGitlab,
        },
        {
            label: "Linkedin",
            link: "https://www.linkedin.com/in/cl%C3%A9ment-lemay/",
            icon: faLinkedinIn,
        }
    ];
    const links: any[] = [];
    options.forEach(x => {
        links.push(Link(x));
    })

    const lee = "{";

    return (
        <div className='profile-container flex gap-8 justify-center items-center' ref={ref} data-visible={isVisible}>
            <div className="flex md:flex-row sm:flex-col-reverse md:justify-center sm:items-center mx-auto max-w-screen-xl px-4 py-32 gap-10 relative sm:w-10/12">
                <div className="flex flex-col items-center justify-center relative items-center md:mx-0 md:w-auto sm:w-3/4 z-10">
                    <span className='curly-braces absolute sm:text-9xl sm:top-0'>{lee}</span>
                    <span className="md:text-4xl sm:text-3xl text-left w-full leading-loose z-10"><em className="text-5xl">👋</em> Hey, je suis</span>
                    <h1 className="md:text-7xl sm:text-4xl font-normal text-transparent md:text-nowrap w-full z-10">
                        Clément Lemay
                    </h1>
                    <p className="text-2xl max-w-xl font-light text-justify pl-8"><b >Ingénieur logiciel,</b> j'aime créer des choses et donner vie <span className="idea-effect">aux idées</span>.</p>
                    <div className='flex flex-row justify-items-end w-full gap-5 mt-7'>{links}</div>
                </div>
                <div className='flex items-center justify-center md:w-auto sm:w-full'>
                    <img className="rounded-full max-w-80 z-10" src={profilePicture} alt="Clément LEMAY" />
                </div>
            </div>
        </div>
    )
}

function Link(props: ILink) {
    return (
        <a className="group relative inline-flex items-center overflow-hidden rounded px-8 py-3 focus:outline-none focus:ring"
            href={props.link} target="_blank" rel="noreferrer">
            <span className="absolute -start-full transition-all group-hover:start-4">
                <FontAwesomeIcon size='2xl' icon={props.icon}></FontAwesomeIcon>
            </span>
            <span className="text-sm font-medium transition-all group-hover:ms-4"> {props.label} </span>
        </a>
    )
}

interface ILink {
    label: string,
    icon: any,
    link: string
}