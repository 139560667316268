import React, { useRef } from 'react';

import './card.css'
import IsOnScreen from '../../services/utils.service.tsx';

export default function Card(props: { data: HistoryCard }) {

    const refCard = useRef<HTMLDivElement>(null);
    const cardIsVisible = IsOnScreen(refCard);

    const badgeList: any[] = [];

    props.data.badges?.forEach(x => {
        badgeList.push(<Badge text={x}></Badge>)
    })

    return (
        <article ref={refCard} data-visible={cardIsVisible} className='history-card flex flex-col w-full relative'>
            <div className='flex flex-col align-center z-10 w-full h-full p-4'>
                <h3 className='text-xl'>{props.data.datesRange}</h3>
                <h4 className='text-lg'>{props.data.title}</h4>
                <p className='tracking-tight whitespace-pre-wrap text-lg mb-12'>{props.data.description}</p>
                <div className='absolute bottom-2 flex flex-wrap px-1 gap-1'>
                    {badgeList}
                </div>
            </div>
            <div className='history-background-card absolute w-full h-full z-5'>
            </div>
        </article>
    )
}

export function Badge(props: { text: string }) {
    return (
        <span className="badge whitespace-nowrap rounded-full px-2.5 py-0.5 text-md text-purple-700 tracking-tighter">
            <span className="text-md">
                {props.text}
            </span>
        </span>)
}

export interface HistoryCard {
    title: string;
    datesRange: string;
    description: string;
    badges: string[];

}
