import React, { useEffect, useMemo, useRef, useState } from 'react';
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";

import './particles.css'
import EventEmitter, { Events } from '../../services/event.tsx';

let defaultOptions = {
    fullScreen: {
        enable: false
    },
    fpsLimit: 60,
    interactivity: {
        events: {
            onHover: {
                enable: true,
                mode: "repulse"
            },
            resize: true
        },
        modes: {
            push: {
                quantity: 4
            },
            repulse: {
                distance: 150,
                duration: 1.2
            }
        }
    },
    particles: {
        color: {
            value: "#ffffff"
        },
        links: {
            color: "#ffffff",
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1
        },
        collisions: {
            enable: true
        },
        move: {
            direction: "none",
            enable: true,
            outModes: {
                default: "bounce"
            },
            random: false,
            speed: 0.5,
            straight: false
        },
        number: {
            density: {
                enable: true,
                area: 800
            },
            value: 120
        },
        opacity: {
            value: 0.5
        },
        shape: {
            type: "circle"
        },
        size: {
            value: { min: 1, max: 5 }
        }
    },
    detectRetina: true
};

EventEmitter.subscribe(Events.theme, (theme: 'dark' | 'light') => {
    const bgColor = getComputedStyle(document.getElementById('root')!).getPropertyValue('--text-color-1');
    if (theme === 'light') {
        defaultOptions.particles.links.color = bgColor;
        defaultOptions.particles.color.value = bgColor;
    } else {
        defaultOptions.particles.links.color = bgColor;
        defaultOptions.particles.color.value = bgColor;
    }
});

export default function CustomParticles() {
    const [init, setInit] = useState(false);
    let [options, setOptions] = useState(defaultOptions);

    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadSlim(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    const particlesLoaded = (container) => {
    };

    if (init) {
        return (
            <div className='particles-container'>
                <Particles
                    id="tsparticles"
                    particlesLoaded={particlesLoaded}
                    options={options}
                />
            </div>
        )

    }

    return <div className='particles-container'></div>;
}