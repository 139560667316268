import React, { useRef } from 'react';

// https://www.webfx.com/tools/emoji-cheat-sheet/

import './projects.css';
import IsOnScreen from '../../services/utils.service.tsx';
import {Card, ICard} from './card.tsx';

export default function Projects() {

    const refTitle = useRef<HTMLDivElement>(null);
    const titleIsVisible = IsOnScreen(refTitle);

    const options: ICard[] = [
        {
            img: "⚙",
            title: "Devops",
            text: "Experimentation et mise en place de multiple services",
            badges: ['Ubuntu', 'Proxmox', 'RabbitMq', 'Grafana', 'Jenkins', 'Docker', 'Kong Api-manager']
        },
        {
            img: "🏗",
            title: "Solutions de web-scrapping",
            text: "Collecte de données, Surveillance, Agrégation de contenu, Enrichissement de données, Automatisation de tâches, Exposition d'API",
            badges: ['Typescript']
        },
        {
            img: "📒",
            title: "Projet d'étude",
            text: "Conception, Pilotage, Déploiemement d'une solution web dans le cadre du projet de fin d'étude",
            badges: ['Angular', 'Jhipster', 'Springboot']
        },
        {
            img: "🤖",
            title: "Automate discord",
            text: "Automatisation de tâches, Interaction avec les utilisateurs Hebergement d'automates discord",
            badges: ['Javascript']
        },
        {
            img: "📱",
            title: "Arduino",
            text: "Développement de différentes solutions IOT permettant le pilotage d'appareils connectés via des cartes programmable Arduino",
            badges: ['C++']
        },
    ];

    const cards : any[] = [];
    options.forEach(x => {
        cards.push(Card(x))
    })

    return (
        <div className='flex flex-col'>
            <h4 className='md:text-4xl sm:text-2xl font-meduim tracking-widest text-center' ref={refTitle} data-visible={titleIsVisible}>PROJETS</h4>
            <div className='card-container flex flex-row flex-wrap gap-16'>
                {cards}
            </div>
        </div>
    )
}

