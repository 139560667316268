import Plausible from "plausible-tracker"
import axios from 'axios'

export function SendStatistic() {
    const { trackPageview } = Plausible({
        domain: 'clementlemay.fr',
        apiHost: 'https://api.clementlemay.fr/plausible',
        hashMode: true,
        // trackLocalhost: true,
    });
    console.log(document.referrer)
    trackPageview({ referrer: document.referrer }, { callback: () => console.log('LogSend') });
}

export function TEST() {
    const plausible = Plausible({

    })
}
