import React, { useRef } from 'react';

import './footer.css';


export default function Footer() {
    return (
        <footer className='relative mt-5'>
            <div className="flex flex-col gap-4 items-center my-8">
                <p className="mx-auto max-w-md text-center leading-relaxed">
                    Conçu et réalisé par Clément Lemay
                </p>
                <p className="mx-auto max-w-md text-center leading-relaxed">
                    ©Copyright 2024 - Clément Lemay
                </p>
            </div>
        </footer>
    )
}