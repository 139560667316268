import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IsOnScreen from "../../services/utils.service.tsx";

import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';

import { faAngular, faNodeJs, faReact, faDocker, faJenkins, faFigma, faJava, faGit, faGithub } from "@fortawesome/free-brands-svg-icons";

import tailwindSvg from './../../assets/svg/tailwind.svg';
import typescriptSvg from './../../assets/svg/typescript.svg';

import './knowledge.css'



export default function Knowledge() {
    library.add(faAngular, faNodeJs, faReact, faDocker, faJenkins, faFigma, faJava, faGithub);

    const refList = useRef<HTMLDivElement>(null);
    const listIsVisible = IsOnScreen(refList);

    const refTitle = useRef<HTMLDivElement>(null);
    const titleIsVisible = IsOnScreen(refTitle);

    const options: ISection[] = [
        {
            title: 'DESIGN',
            line: [
                { label: 'Figma', icon: faFigma, isEpic: false },
                { label: 'Tailwind', customIcon: "fa-tailwind", isEpic: false },
            ],
        },
        {
            title: 'FRONT-END',
            line: [
                { label: 'Typescript', customIcon: "fa-typescript", isEpic: false },
                { label: 'Angular', icon: faAngular, isEpic: true },
                { label: 'React', icon: faReact, isEpic: false },
            ],
        },
        {
            title: 'BACK-END',
            line: [
                { label: 'Node', icon: faNodeJs, isEpic: true },
                { label: 'Java', icon: faJava, isEpic: false },
            ],
        },
        {
            title: 'OTHER',
            line: [
                { label: 'Jenkins', icon: faJenkins, isEpic: true },
                { label: 'Docker', icon: faDocker, isEpic: true },
                { label: 'Git & Github', icon: faGithub, isEpic: false },
            ],
        },
    ];

    const sections: any[] = [];
    options.forEach(x => {
        sections.push(Section(x))
    });


    return (
        <div className='flex flex-col w-3/4'>
            <h4 className='md:text-4xl sm:text-2xl font-meduim tracking-widest text-center mb-10 ' ref={refTitle} data-visible={titleIsVisible}>CONNAISSANCES</h4>
            <div className="flex md:flex-row sm:flex-col justify-evenly gap-10 knowledge-container" ref={refList} data-visible={listIsVisible}>
                {sections}
            </div>
        </div>
    )
}

function Section(props: ISection) {
    const rows: any[] = [];
    props.line?.forEach(x => {
        let iconElement = <></>;
        let defaultClass = 'flex flex-row md:text-2xl sm:text-lg gap-3'
        if (x.customIcon) {
            iconElement = <i className={x.customIcon}></i>
        }
        if (x.icon) {
            iconElement = <FontAwesomeIcon icon={x.icon} />
        }
        rows.push(<li className={x.isEpic ? `epic-font ${defaultClass}` : `${defaultClass}`}>{iconElement}{x.label}</li>)
    });
    return (
        <section className="flex flex-col">
            <h3 className="text-nowrap md:text-3xl sm:text-2xl">{props.title}</h3>
            {rows}
        </section>)
}

interface ISection {
    title: string,
    line: { label: string, icon?: IconDefinition | null, customIcon?: any, isEpic: boolean }[]
}