import { RefObject, useEffect, useMemo, useState } from "react"
import EventEmitter, { Events } from "./event.tsx"
import React from "react"

export default function IsOnScreen(ref: RefObject<HTMLElement>) {
    const [isIntersecting, setIntersecting] = useState(false)

    const observer = React.useMemo(() => new IntersectionObserver(
        ([entry]) => {
            setIntersecting(entry.isIntersecting)
        }
        // eslint-disable-next-line
    ), [ref]);


    React.useEffect(() => {
        observer.observe(ref.current!)
        return () => observer.disconnect()
        // eslint-disable-next-line
    }, []);
    return isIntersecting;
}

// const darkThemeMq = false;
const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
export let theme: 'dark' | 'light' = darkThemeMq ? 'dark' : 'light';

export function initTheme() {
    document.getElementById('root')!.setAttribute('class', theme);
}

export function ToggleTheme() {
    let classList = document.getElementById('root')!.classList;
    if (theme === 'dark') {
        theme = 'light';
        classList.replace('dark', 'light');
    } else {
        theme = 'dark';
        classList.replace('light', 'dark');
    }
    EventEmitter.dispatch(Events.theme, theme);
}

