import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import LandingPage from './components/LandingPage/LandingPage.tsx';
import reportWebVitals from './reportWebVitals';

document!.getElementById('root')?.setAttribute('class', 'dark')
const root = ReactDOM.createRoot(document!.getElementById('root'));


root.render(
    <><script src="https://unpkg.com/twemoji@latest/dist/twemoji.min.js" crossOrigin="anonymous"></script><LandingPage /></>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
